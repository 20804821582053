export const fetchMethods = {
	GET: 'GET',
	POST: 'POST',
	PUT: 'PUT',
	PATCH: 'PATCH',
	DELETE: 'DELETE',
};

export const testingSteps = ['Enter Testing details', 'Enter Session details', 'Start Testing'];

export const roles = {
	SUPERUSER: 'hac.admin',
	MANUFACTURING_ADMIN: 'hac.manufacturing.admin',
	MANUFACTURING_TESTER: 'hac.manufacturing.tester',
	MANUFACTURING_COORDINATOR: 'hac.manufacturing.coordinator',
};

export const testingType = {
	moduleTester: 'MT',
	moduleMatcher: 'MM',
	fullDeviceTester: 'FD',
	packagingTester: 'PT',
	audioModule: 'AM',
};

export const reactSelectStyles = {
	singleValue: (baseStyles) => ({
		...baseStyles,
		color: '#777',
	}),
	control: (baseStyles) => ({
		...baseStyles,
		backgroundColor: 'transparent',
	}),
	menu: (provided) => ({
		...provided,
		zIndex: 123123,
	}),
	option: (styles, { isFocused }) => {
		styles[':active'].backgroundColor = '#fff';
		return {
			...styles,
			backgroundColor: isFocused ? '#999999' : null,
			color: '#333333',
		};
	},
	input: (baseStyles) => ({
		...baseStyles,
		color: '#777',
	}),
};

/// using this data until backend is configured

export const moduleTestingValidations = {
	'hello 3': {
		'mic board': {
			conventions: ['3MR', '3ML'],
			lengths: [13],
		},
		'main board': {
			conventions: ['H3B'],
			lengths: [13],
		},
		'led board': {
			conventions: ['H3L', 'H2L', 'H2+L'],
			lengths: [13, 14],
		},
		'hello pillow board': {
			conventions: ['H3P'],
			lengths: [13],
		},
	},
	'expand vision 5': {
		'main board': {
			conventions: ['BBO'],
			lengths: [13],
		},
		sbab: {
			conventions: ['BSB'],
			lengths: [13],
		},
		'mic board': {
			conventions: ['BMI'],
			lengths: [13],
		},
		'led board': {
			conventions: ['BLE'],
			lengths: [13],
		},
	},
	'expand control uc': {
		'main board': {
			conventions: ['GBO'],
			lengths: [13],
		},
		'led board': {
			conventions: ['GLL', 'GLR'],
			lengths: [13],
		},
	},
};

export const moduleMatchingConfigs = {
	ecuc: [
		{
			deviceHwModuleId: 1,
			deviceHwModuleName: 'Main Board',
			inputName: 'mainBoard',
			placeholder: 'Main Board SN',
			modulePart: '',
		},
		{
			deviceHwModuleId: 2,
			deviceHwModuleName: 'Led Left Board',
			inputName: 'ledLeftBoard',
			placeholder: 'Prefix SN: GLL',
			modulePart: 'L',
		},
		{
			deviceHwModuleId: 2,
			deviceHwModuleName: 'Led Right Board',
			inputName: 'ledRightBoard',
			placeholder: 'Prefix SN: GLR',
			modulePart: 'R',
		},
		{
			deviceHwModuleId: 8,
			deviceHwModuleName: 'Proximity Board',
			inputName: 'proximityBoard',
			placeholder: 'Proximity Board SN',
			modulePart: '',
		},
	],
	h2: [
		{
			deviceHwModuleId: 1,
			deviceHwModuleName: 'Main Board',
			inputName: 'mainBoard',
			placeholder: 'Main Board SN',
			modulePart: '',
		},
		{
			deviceHwModuleId: 3,
			deviceHwModuleName: 'Daughter Board',
			inputName: 'daughterBoard',
			placeholder: 'Daughter Board SN',
			modulePart: '',
		},
		{
			deviceHwModuleId: 2,
			deviceHwModuleName: 'Led Board',
			inputName: 'ledBoard',
			placeholder: 'Led Board SN',
			modulePart: '',
		},
		{
			deviceHwModuleId: 6,
			deviceHwModuleName: 'Camera SN',
			inputName: 'camera',
			placeholder: 'Camera SN',
			modulePart: '',
		},
	],
	'h2+': [
		{
			deviceHwModuleId: 1,
			deviceHwModuleName: 'Main Board',
			inputName: 'mainBoard',
			placeholder: 'Main Board SN',
			modulePart: '',
		},
		{
			deviceHwModuleId: 2,
			deviceHwModuleName: 'Led Board',
			inputName: 'ledBoard',
			placeholder: 'Led Board SN',
			modulePart: '',
		},
		{
			deviceHwModuleId: 6,
			deviceHwModuleName: 'Camera SN',
			inputName: 'camera',
			placeholder: 'Camera SN',
			modulePart: '',
		},
	],
	ev3: [
		{
			deviceHwModuleId: 1,
			deviceHwModuleName: 'Main Board',
			inputName: 'mainBoard',
			placeholder: 'Main Board SN',
			modulePart: '',
		},
		{
			deviceHwModuleId: 3,
			deviceHwModuleName: 'Daughter Board',
			inputName: 'daughterBoard',
			placeholder: 'Daughter Board SN',
			modulePart: '',
		},
		{
			deviceHwModuleId: 2,
			deviceHwModuleName: 'Led Board',
			inputName: 'ledBoard',
			placeholder: 'Led Board SN',
			modulePart: '',
		},
		{
			deviceHwModuleId: 6,
			deviceHwModuleName: 'Camera SN',
			inputName: 'camera',
			placeholder: 'Camera SN',
			modulePart: '',
		},
	],
	ev5: [
		{
			deviceHwModuleId: 1,
			deviceHwModuleName: 'Main Board',
			inputName: 'mainBoard',
			placeholder: 'Main Board SN',
			modulePart: '',
		},
		{
			deviceHwModuleId: 7,
			deviceHwModuleName: 'SBAB',
			inputName: 'sbab',
			placeholder: 'SBAB',
			modulePart: '',
		},
		{
			deviceHwModuleId: 2,
			deviceHwModuleName: 'Led Board',
			inputName: 'ledBoard',
			placeholder: 'Led Board SN',
			modulePart: '',
		},
		{
			deviceHwModuleId: 6,
			deviceHwModuleName: 'Camera SN',
			inputName: 'camera',
			placeholder: 'Camera SN',
			modulePart: '',
		},
		{
			deviceHwModuleId: 4,
			deviceHwModuleName: 'Mic Board',
			inputName: 'micBoard',
			placeholder: 'Mic Board',
			modulePart: '',
		},
	],
	ev3t: [
		{
			deviceHwModuleId: 1,
			deviceHwModuleName: 'Main Board',
			inputName: 'mainBoard',
			placeholder: 'Main Board SN',
			modulePart: '',
		},
		{
			deviceHwModuleId: 3,
			deviceHwModuleName: 'Daughter Board',
			inputName: 'daughterBoard',
			placeholder: 'Daughter Board SN',
			modulePart: '',
		},
		{
			deviceHwModuleId: 2,
			deviceHwModuleName: 'Led Board',
			inputName: 'ledBoard',
			placeholder: 'Led Board SN',
			modulePart: '',
		},
		{
			deviceHwModuleId: 6,
			deviceHwModuleName: 'Camera SN',
			inputName: 'camera',
			placeholder: 'Camera SN',
			modulePart: '',
		},
	],
	ev5t: [
		{
			deviceHwModuleId: 1,
			deviceHwModuleName: 'Main Board',
			inputName: 'mainBoard',
			placeholder: 'Main Board SN',
			modulePart: '',
		},
		{
			deviceHwModuleId: 7,
			deviceHwModuleName: 'SBAB',
			inputName: 'sbab',
			placeholder: 'SBAB',
			modulePart: '',
		},
		{
			deviceHwModuleId: 2,
			deviceHwModuleName: 'Led Board',
			inputName: 'ledBoard',
			placeholder: 'Led Board SN',
			modulePart: '',
		},
		{
			deviceHwModuleId: 6,
			deviceHwModuleName: 'Camera SN',
			inputName: 'camera',
			placeholder: 'Camera SN',
			modulePart: '',
		},
		{
			deviceHwModuleId: 4,
			deviceHwModuleName: 'Mic Board',
			inputName: 'micBoard',
			placeholder: 'Mic Board',
			modulePart: '',
		},
	],
	h3: [
		{
			deviceHwModuleId: 1,
			deviceHwModuleName: 'Main Board',
			inputName: 'mainBoard',
			placeholder: 'Main Board SN',
			modulePart: '',
		},
		{
			deviceHwModuleId: 2,
			deviceHwModuleName: 'Led Board',
			inputName: 'ledBoard',
			placeholder: 'Led Board SN',
			modulePart: '',
		},
		{
			deviceHwModuleId: 6,
			deviceHwModuleName: 'Camera SN',
			inputName: 'camera',
			placeholder: 'Camera SN',
			modulePart: '',
		},
		{
			deviceHwModuleId: 9,
			deviceHwModuleName: 'Pillow Speaker',
			inputName: 'pillowSpeaker',
			placeholder: 'Pillow Speaker',
			modulePart: '',
		},
	],
};

export const generalConfigurationsTypes = {
	DEACTIVATED_CONFIGURATIONS: 'deActivatedConfigurations',
	CONFIGURATIONS_HISTORY: 'generalConfigurationsHistory',
	GENERAL_CONFIGURATIONS: 'generalConfigurations',
};

export const generalConfigurationsCategory = {
	TESTING_SESSION_CONFIGURATIONS: 'testing_session_configurations',
	MASTER_MODEL: 'master_model',
};

export const usersTables = {
	users: 'users',
	invites: 'invites',
	deactivated: 'deactivated',
};

export const calibrationsTables = {
	tests: 'tests',
	profiles: 'profiles',
	setups: 'setups',
};

export const factoryTestingStations = {
	MODULE_TESTER: 1,
	MODULE_MATCHER: 2,
	FULL_DEVICE_TESTER: 3,
	PACKAGING: 4,
	AUDIO_MODULE: 5,
};

export const factoryTestingStatus = {
	TEST_IN_PROGRESS: 1,
	TEST_COMPLETED: 2,
	TEST_CANCELED_MANUALLY: 3,
	TEST_FAILED_UPLOADING: 4,
	TEST_SESSION_EXPIRED: 5,
	TEST_FAILED: 6,
	DEVICE_REPACKAGED: 7,
	PRODUCT_RETESTED: 8,
	CERTIFICATE_INSTALLATION_FAILED: 9,
	PENDING_APPROVAL: 10,
	WAIVED: 11,
};

export const pageTitles = {
	DASHBOARD: 'Dashboard',
	USERS: 'Users',
	AUDIO_ANALYSIS: 'Audio Analysis',
	TESTED_DEVICES: 'Tested Devices',
	DEVICE_DETAILS: 'Device Details',
	DEVICE_TESTING_HISTORY: 'Device Testing History',
	MIC_CALIBRATIONS: 'Mic Calibrations',
	TESTING_APPS: 'Testing Apps',
	TESTING_APPS_DETAILS: 'Testing Apps Details',
	TFS_RELEASES: 'Tfs Releases',
	WATS_REPORTS: 'WATS Reports',
	MODULE_TESTER: 'Factory Module Tester',
	MODULE_MATCHER: 'Factory Module Matcher',
	FULL_DEVICE_TESTER: 'Factory Full Device Tester',
	FACTORY_PACKAGING: 'Factory Packaging',
	SHIPPING_REQUESTS: 'Shipping Requests',
	SHIPPING_REQUESTS_DETAILS: 'Shipping Request Details',
	CREATE_SHIPPING_REQUEST: 'Create Shipping Request',
	GENERAL_CONFIGURATIONS: 'General Configurations',
	APP_MODULE_CONFIGURATIONS: 'App Module Configurations',
	FACTORY_FILES: 'Factory Files',
	AUDIO_MODULE_TESTER: 'Audio Module Tester',
};

export const WATSTestSessionsID = 6;

export const ElectricalTestsTableID = 7;

export const appearanceModes = {
	DARK_MODE: 'dark',
	LIGHT_MODE: 'light',
};

export const dashboardChartTypes = {
	Barchart: 1,
	PieChart: 2,
};

export const yieldTypes = {
	YieldByStation: 2,
	YieldBySku: 3,
	YieldByStationAndSKU: 10,
};
export const deviceHwModulesID = {
	MAIN_BOARD: 1,
	LED_BOARD: 2,
	DAUGHTER_BOARD: 3,
	MIC_BOARD: 4,
	PRIVACY_BUTTON_BOARD: 5,
	CAMERA_MODULE_IQC: 6,
	SBAB: 7,
	PROXIMITY_BOARD: 8,
	HELLO_PILLOW_BOARD: 9,
	XMOS_BOARD: 11,
};

export const deviceCodes = {
	HELLO_3: 'H3',
	HELLO_PTZ_CAM: 'HPC',
	IRG: 'IRG',
	EXPAND_CONTROL_UC: 'ECUC',
	EXPAND_VISION_5: 'EV5',
	EXPAND_VISION_3: 'EV3',
	HELLO_2_PLUS: 'H2+',
	HELLO_2: 'H2',
};

export const configurationCategories = {
	SESSION_CONFIGURATION: 1,
	GENERAL_CONFIGURATION: 2,
};

export const chartTypes = {
	AUDIO: 'audio',
	MODULE: 'module',
};

export const testBoxes = {
	TB1: 'TB200001',
	TB2: 'TB200002',
	TB3: 'TB200003',
	TB4: 'TB200004',
	KS1: 'KS00001',
	BT1: 'BT300001',
};

export const factoryFileStatuses = [
	{
		id: 1,
		name: 'Open',
	},
	{
		id: 2,
		name: 'InReview',
	},
	{
		id: 3,
		name: 'Approved',
	},
	{
		id: 4,
		name: 'Rejected',
	},
];

export const deviceFamilyIds = {
	HELLOCARE_TV_55: 24,
	HELLOCARE_TV_43: 23,
	HELLOCARE_TV_32: 22,
	HELLOCARE_TV_27: 21,
	HELLOCARE_TABLET_15: 20,
	HELLOCARE_TABLET_13: 19,
	HELLOCARE_TABLET_10: 17,
	HELLO_PTZ: 15,
	HELLO_3: 13,
	EXPAND_CONTROL_UC: 9,
	EXPAND_VISION_5: 6,
	HELLO_2_PLUS: 4,
	HELLO_2: 3,
	HELLO_1: 1,
};

export const testingStepsIds = {
	testingForm: 1,
	testingSessionForm: 2,
	testingSession: 3,
};
